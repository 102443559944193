import React,{useState} from 'react';



/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl}  from '../../environment';


function SignUpEndUserForm(){

    const [email, setEmail]=useState('');
    const [username, setUsername]=useState('');
    const [password, setPassword]=useState('');

    const handleForm=(e)=>{
        e.preventDefault();

        const data={
            email:email,
            username:username,
            password:password
        }
        new RestServer().setUrl(conurl+'/api/cms/signup')
        .setMethod('POST')
        .flagReturnData(false)
        .setPostBody(data)
        .setRedirect("/login")
        .connect(); 
    }
    return(
        <React.Fragment>
            <Form onSubmit={handleForm}>
                    <Row className="p-l-75 p-r-100">
                        <Col lg={4}></Col>
                        <Col className="shadow m-10 rad-25" lg={4}>
                            <Row className="p-t-25 m-10"><Col className="font-200 center">SIGN UP</Col></Row>
                            <Row className="p-t-25 m-10">
                                <Col className="center">
                                    <span className="font-heavy"><a href="/endusersignup">User</a></span>&nbsp; &nbsp; | 
                                    &nbsp;&nbsp; <span><a href="/managementsignup">Event Management</a></span> 
                                </Col>
                            </Row>
                            <Row className="p-t-25 m-10">
                                <Col className="m-10">
                                   
                                    <Row className="p-t-10">
                                        <Col>
                                            <Form.Group controlId="email" onChange={(s)=>{setEmail(s.target.value)}}>
                                                <Form.Control className="clr-black" type="email" placeholder="Email Address" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-10">
                                        <Col>
                                            <Form.Group controlId="username" onChange={(s)=>{setUsername(s.target.value)}}>
                                                <Form.Control className="clr-black" type="text" placeholder="Username" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="p-t-10">
                                        <Col>
                                            <Form.Group controlId="password" onChange={(s)=>{setPassword(s.target.value)}}>
                                                <Form.Control className="clr-black" type="password" placeholder="Password" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="p-t-10 m-10">
                                <Col lg={1}><Form.Check type="checkbox" label="" /></Col>
                                <Col>Keep me signed in</Col>
                                <Col>Forgot?</Col>
                            </Row>
                            <Row className="p-t-10 m-10">
                                <Col lg={1}><Form.Check type="checkbox" label="" /></Col>
                                <Col>Accept terms and condition</Col>
                            </Row>

                            <Row className="p-t-25 p-b-25 m-10 txt-center">
                                
                                <Col> <Button variant="danger" type="submit">CREATE ACCOUNT</Button>{' '}</Col>
                                <Col><Button variant="danger">CANCEL</Button>{' '}</Col>
                                
                            </Row>
                        </Col>
                        <Col lg={4}></Col>
                    </Row>
                </Form>
        </React.Fragment>
    );
}
export default SignUpEndUserForm;