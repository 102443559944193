import create from 'zustand';
import { persist } from 'zustand/middleware';



const cartDataStore=create(
    persist(
        (set,get)=>({
            cartData: {},
            addCartData:(payload)=>set((state)=>({cartData:payload})),
            clearCartData:()=>set(()=>({cartData:[]})),
        })
    )
);

export default cartDataStore;