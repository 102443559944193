import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import {dateFormat} from '../util/DateFormatter';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, producturl, rtcom}  from '../../environment';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCartShopping, faList, faQrcode} from '@fortawesome/free-solid-svg-icons';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function ListEventProductsContent(){
    const history = useNavigate();

    const[event, setEvent]=useState(null);
    const[tickets, setTickets]=useState(null);
    const[products, setProducts]=useState(null);
    const [cart, setCart] = useState(null);

    const routeClick=(path)=>{
        history(path, {replace:true})
    }



    const handleAddTicket=(e, i)=>{
        console.info("handleAddTicket :");
        console.info(e);

        console.info("handleAddTicket->cart:");
        console.info(cart);

        if(cart===null){ 
            console.info("handleAddTicket->cart is null:");      
            var cprod=[];
            
            const product={
                productcategoryid:e.category.categoryid,
                productcategorytype:e.category.categorycode,
                venueid:event.venueid,
                productid:e.productId,
                eventId:e.eventId,
                ticketIndexID:i,
                taxesId:event.taxesId,
                quanity:1,
                isTaxable:event.taxes
            }
            cprod.push(product);
    
            var cartD={
                products:cprod
            }

        }else{
            console.info("handleAddTicket->cart is not null:");     
            
            var cprod=cart.products;
            const product={
                productcategoryid:e.category.categoryid,
                productcategorytype:e.category.categorycode,
                venueid:event.venueid,
                productid:e.productId,
                eventId:e.eventId,

                ticketIndexID:i,
                taxesId:event.taxesId,
                quanity:1,
                isTaxable:event.taxes
            }
            cprod.push(product);
    
            cartD={
                active:cart.active,
                activetil:cart.activetil,
                cartId:cart.cartId,
                datecreated:cart.datecreated,
                products:cprod,
                userid:cart.userid
            }            
        }
        console.info(cartD);
      
        //THIS would add to chart
        new RestServer().setUrl(conurl+'/api/cms/addtocart')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(cartD)
        .connect()
        .then(response=>{
            const rtndata =response.data;
     
            if(rtndata.active){
                toast("Ticket is Added Successfully to Cart");
                setCart(rtndata);
                localStorage.setItem("usercart", JSON.stringify(rtndata));
            }else{
                toast("Ticket was not added please try again at a later time");
            }
        },[]);
    }

  
    const handleGetEventProducts=(eid)=>{
        console.info("handleGetEventProducts->eid:");
        console.info(eid);
        new RestServer().setUrl(producturl+'/api/prod/ineventproducteventid/'+eid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            const prod=res.data;
            console.info("handleGetEventProducts->prod: ");
            console.info(prod);

            var inEventProducts=[];
            prod.map((p)=>{
                console.info("prod->p: ");
                console.info(p);
                if(p.category.categorycode==="INEVENTPRODUCT"){
                    console.info("INEVENTPRODUCT->p: ");
                    console.info(p)
                    inEventProducts.push(p);
                }
            })
            console.info("inEventProducts:");
            console.info(inEventProducts);
            setProducts(inEventProducts);
           // setEvent(eventdata);
        },[]);

    }


    const handleGetEventData=(eid)=>{
        console.info("handleGetEventData->product: ");
        console.info(eid);
        console.info("eid?.orderInfo.purchase.products[0].eventId :");
        console.info(eid?.orderInfo.purchase.products[0].eventId);
        new RestServer().setUrl(producturl+'/api/prod/event/'+eid?.orderInfo.purchase.products[0].eventId)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            const eventdata=res.data;
            console.info("handleGetEventData->product->eventdata: ");
            console.info(eventdata);
            handleGetEventProducts(eventdata.eventId)
            setEvent(eventdata);
            
        },[]);

    }

    useEffect(() => {
        console.info("ListEventTicketsContent->useEffect");

         const urlData = window.location.pathname.split("/");
         
         //GET TICKET PURCHASE
        new RestServer().setUrl(producturl+'/api/prod/event/'+urlData[2])
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            setEvent(res.data);
            handleGetEventProducts(urlData[2]);
                
     
        },[]);
    },[]);

    return(
        <React.Fragment>
                <Row className="p-b-25">
                <Col className="font-size-150 font-heavy8"> My Event Tickets</Col>
            </Row>
            <Row>
                <Col lg={10}>
                    <Row className="glass-container-2 p-10" >
                        <Col lg={3} ><img src={event?.eventimage[0].imageloc+event?.eventimage[0].imagename} className="display-img" /></Col>
                        <Col >
                            <Row><Col className="font-size-150 font-heavy8">{event?.eventname}</Col></Row>
                            <Row><Col className="p-t-5 font-size-100 clr-1 font-heavy8">{dateFormat.getformateddate(event?.eventdates[0].epochtime)} - {dateFormat.eventCompleted(event?.eventdates[0].epochtime)?"CLOSED":""}</Col></Row> 
                            <Row><Col className="p-t-10 font-size-100">{event?.eventdescription}</Col></Row>
                                       
                            <Row className="p-t-75">
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{routeClick("/tickets")}}>
                                        <FontAwesomeIcon icon={faList} />
                                        <span className="icon-container-1-title">List Events
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>         
                                </Col>
                                <Col className="right">
                                    <button className="icon-container-1" onClick={()=>{routeClick("/listeventtickets/"+event?.eventId)}}>
                                        <FontAwesomeIcon icon={faQrcode} />
                                        <span className="icon-container-1-title">Event Tickets<div className="icon-container-1-title-pointer"></div></span>
                                    </button>
                                </Col>
                                
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="p-t-25 p-b-25">
                <Col className="font-size-125 font-heavy8"> Event Products</Col>
            </Row>
            <Row className="p-l-100">
            {
                products?.map((product, index)=>{
                    var prodimage = product?.images[0]?.imageloc+product?.images[0]?.imagename;
                    return <Col lg={3} className="glass-container-1">
                  
                                <Row className="p-t-10">
                                    <Col>
                                        <img src={prodimage}  className="img-container-1 rad-tl-5 rad-tr-5"/>
                                    </Col>
                                </Row>
                               
                                <Row className="p-t-25 font-size-150 font-heavy p-l-10 p-r-10">
                                    <Col>{product?.productName}</Col>
                                </Row>
                               
                                <Row className="p-t-25 font-size-75 p-l-10  p-r-10">
                                    <Col>{product?.productDescription}</Col>
                                </Row>
                                <Row className="p-t-25 p-b-25 p-l-10  p-r-10">
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{handleAddTicket(product, index)}}>
                                        <FontAwesomeIcon icon={faCartShopping} />
                                        <span className="icon-container-1-title">Add to Cart
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>
                                </Col>
                                    
                                   
                                </Row>
                        
                            </Col>
                            
                            
                })
            }
            </Row>
         
        </React.Fragment>
    );
}
export default ListEventProductsContent;