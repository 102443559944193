import React from 'react';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*COMPONENTS */
import TopHeader from '../components/ui/TopHeader';
import ListEventTicketsContent from '../components/tickets/listeventticketscontent';
import Footer from '../components/ui/footer';

function ListEventTickets(){
    return(
        <React.Fragment>
            <Row>
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-25">
               
                <Col>
                    <ListEventTicketsContent />
                </Col>
                
            </Row>
           
        </React.Fragment>
    );
}
export default ListEventTickets;