import React,{useEffect} from 'react';
import { useNavigate } from "react-router-dom";


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//INTERNAL
import TopHeader from '../components/ui/TopHeader';
import CartProcess from '../components/cart/cartprocess';
import Footer from '../components/ui/footer'


function CartPay(){

    const history = useNavigate();

    useEffect(() => {
        const cdata = JSON.parse(localStorage.getItem('usercart'));
        const userData = JSON.parse(localStorage.getItem('userdata'));

        if(userData===null){ history("/login", {replace:true})}
        if(cdata===null){ history("/", {replace:true})}


    });

    return(
        <React.Fragment>
            <Row>
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-50">
                <Col lg={2}></Col>
                <Col lg={8} >
                    <CartProcess/>
                </Col>
                <Col lg={2}></Col> 
            </Row>
            <Row >
                <Col lg={12} className="bottom"><Footer /></Col>
            </Row>
    
        
        </React.Fragment>
    );
}
export default CartPay;