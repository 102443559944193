'use strict';
import moment from 'moment';

export  const dateFormat={

    futureEvent:function(eventtime){
        const cTime=moment().unix()*1000;
        var rtn=false;
        if(cTime<eventtime.startDate){
            rtn=true;
        }
        return rtn
    },
    eventCompleted:function(eventtime){
        const cTime=moment().unix()*1000;
        var etime;
        var rtn=false;

        if(typeof eventtime === 'string'){
            etime=parseInt(eventtime);

        }else{
            etime=eventtime;
        }

        
        if(cTime>etime){
            rtn=true;
        }
        return rtn;
    },
    pastEvent:function(eventtime){
        const cTime=moment().unix()*1000;
        var rtn=false;
        if(cTime>eventtime.endDate){
            rtn=true;
        }
        return rtn

    },
    eventToday:function(eventrange){
        const startOfDay=moment().startOf('day').unix()*1000;
        const endOfDay=moment().endOf('day').unix()*1000;
        var rtn=false;
        if(eventrange.startDate <= startOfDay && eventrange.endDate>=endOfDay){
            rtn=true; 
        }
        return rtn;
    },
    eventThisWeek:function(eventrange){
        const startOfWeek=moment().startOf('week').unix()*1000;
        const endOfWeek=moment().endOf('week').unix()*1000;

        console.info(startOfWeek);
        console.info(endOfWeek);

        var rtn=false;
        if(eventrange.startDate <= startOfWeek && eventrange.endDate>=endOfWeek){
            rtn=true; 
        }
        return rtn;

    },
    eventThisWeekend:function(eventrange){
        const startOfWeekend=moment().day(6).startOf('day').unix()*1000;
        const endOfWeekend=moment(startOfWeekend).add('day',2).unix()*1000;
        var rtn=false;
        if(eventrange.startDate <= startOfWeekend && eventrange.endDate>=endOfWeekend){
            rtn=true; 
        }
        return rtn;
       

    },
    eventStarted:function(eventrange){
        const eTime=moment().unix()*1000;
        const threeHours= 3*3600000;//three hours
        const cTime=eTime+threeHours;//current time + 3 hours

        var rtn=false;
        if(eventrange.startDate <= eTime && eventrange.endDate>=cTime){
            rtn=true; 
        }
        return rtn;
    },

    getformateddate: function(epoch){

        var etime;

        if(typeof epoch === 'string'){
            etime=parseInt(epoch);

        }else{
            etime=epoch;
        }

        var day = moment(etime); //milliseconds
        return day.format('dddd MMMM Do YYYY, h:mm:ss a');
    },
    getMMMDDYYY: function(epoc){
        var etime;
        if(typeof epoc === 'string'){
            etime=parseInt(epoc);

        }else{
            etime=epoc;
        }

        var day = moment(etime); //milliseconds
        return day.format('MMM Do YYYY');

    },
    getDDDHSSA: function(epoc){
        var etime;
        if(typeof epoc === 'string'){
            etime=parseInt(epoc);

        }else{
            etime=epoc;
        }

        var day = moment(etime); //milliseconds
        return day.format('ddd h:mma');

    },
    getd:function(d){
        return d.day;
    },
    getdd:function(d){
        let r;
        if(d.day.toString().length===1){
            r="0"+d.day;
        }else{
            r=d.day;
        }
        return r;
    },
    getDateLong: function(d){
        const date = new Date();
        date.setDate(d.day -1);
        console.info(date.getDay())
        return date.toLocaleString('en-US');

    }
   
    

};
/*
export const dateFormat=(jsonDate)=>{

    d:jsonDate,

    getRawDate: ()=>{
        return d.day
    };

    
}
*/