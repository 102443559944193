import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";

  import Home from './containers/Home';
  import ProductDetail from './containers/productdetail';
  import Cart from './containers/cart';
  import CartPay from './containers/cartpay';
  import SignupEnduser from './containers/signupenduser';
  import Login from './containers/login';
  import Logout from './containers/logout';
  import LoginRedirect from './containers/loginredirect';
  import Tickets from './containers/tickets';
  import ListEventTickets from './containers/listeventtickets';
  import ListEventProducts from './containers/listeventproducts';
  import EventLinkManagement from './containers/eventlinkmanagement';
  import Favorites from './containers/favorites';

  //Content
  import AboutUs from './containers/about';
  import Support from './containers/support';
  import ContactUsContent from './containers/contactus';
  import PrivacyPolicyContent from './containers/privacypolicy';
  import CookiePolicyContent from './containers/cookiepolicy'
  import TermsConditionContent from './containers/termcondition';
  import Help from './containers/help';

function EvntxzMain() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/productdetail/:id" element={<ProductDetail />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/cartpay" element={<CartPay />} />
                <Route path="/endusersignup" element={<SignupEnduser />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/loginredirect" element={<LoginRedirect />} />
                <Route path="/tickets" element={<Tickets />} /> 
                <Route path="/listeventtickets/:id" element={<ListEventTickets />} /> 
                <Route path="/listeventproducts/:id" element={<ListEventProducts />} />
                <Route path="/eventlinkmanagement/:id" element={<EventLinkManagement />} />
                
                <Route path="/favorites" element={<Favorites />} /> 

                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/support" element={<Support />} />
                <Route path="/contactus" element={<ContactUsContent />} />
                <Route path="/privacypolicy" element={<PrivacyPolicyContent />} />
                <Route path="/cookiepolicy" element={<CookiePolicyContent />} />
                <Route path="/termsconditions" element={<TermsConditionContent />} />
                <Route path="/help" element={<Help />} />
                
                

            </Routes> 
        </Router>
    );
}
export default EvntxzMain;