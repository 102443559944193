import React,{useState, useEffect} from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestServer from '../cRestServer';
import {producturl, conurl, orgurl}  from '../../environment';

import {dateFormat} from '../util/DateFormatter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar, faFile, faHeart, faUserPlus, faLocationDot} from '@fortawesome/free-solid-svg-icons';

function EventInfo(){
    const[eventInfo, setEventInfo]=useState(null);
    const[pimage, setPimage]=useState(null);
    const [venue, setVenue]=useState(null);
    const[favorite, setFavorite]=useState(null);
   


    const userdata =JSON.parse(localStorage.getItem('userdata'));


    const handleFavorite = (e)=>{
        const data ={
            producttype:"EVENT",
            productid:eventInfo.eventid,
            usertype:"ENDUSER",
            userid:userdata.userid
        }

        new RestServer().setUrl(conurl+'/api/cms/addfavorite')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            //TODO: ADD Toast
        },[]);
    }

    
    const handleFollow =(e)=>{
        const data ={
            producttype:"EVENT",
            productid:eventInfo.eventid,
            usertype:"ENDUSER",
            userid:userdata.userid
        }

        new RestServer().setUrl(conurl+'/api/cms/addfollow')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            //TODO: ADD Toast
        },[]);
    }

    const favoriteicon=(e)=>{
        //console.info("favoriteicon");
        /*
        var favicon;

        if(favorite===null){
            favicon="bck-clr-light-grey p-1 rad-25";
        }else{
            favicon="bck-clr-primary-8 p-1 rad-25";
        }
        

        return favicon;
        */
    }

    useEffect(() => {

        const urlData = window.location.pathname.split("/");

        new RestServer().setUrl(producturl+'/api/prod/event/'+urlData[2])
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const rtndata=response.data
            console.info("EventInfo: ");
            console.info(rtndata)
            setEventInfo(rtndata);

            new RestServer().setUrl(orgurl+'/api/org/byvenueid/'+rtndata.venueid)
            .setMethod('GET')
            .flagReturnData(true)
            .connect()
            .then(res=>{
                const venueinfo = res.data;
                //console.info(venueinfo);
                setVenue(venueinfo);
            })
            const primaryImage = rtndata.eventimage[0].imageloc+rtndata.eventimage[0].imagename;
            setPimage(primaryImage);
            
        });

        //GET FOLLOW
        //console.info("GET FOLLOW");
        if(userdata !==null){

            const followdata ={
                "productid":urlData[2],
                "userid":userdata.userid
            }

            new RestServer().setUrl(conurl+'/api/cms/favoritebyuserprod')
            .setMethod('POST')
            .flagReturnData(true)
            .setPostBody(followdata)
            .connect()
            .then(fellowresp=>{
                const fav = fellowresp.data;
                //console.info("fav: ");
                //console.info(fav);
                setFavorite(fav);
            },[]); 
        }

        //GET FAVORITE
        if(userdata !==null){
            
        }

    },[]);


    return(
        <React.Fragment>
              
            <Row>
                <Col lg={4} ><img src={pimage} className="img-container-1" /></Col>

                <Col lg={5} >

                    <Row><Col className="font-size-150 font-heavy8">{eventInfo?.eventname}</Col></Row>
                    <Row><Col className="p-t-5 font-size-100 font-heavy8 clr-1">{dateFormat.getformateddate(eventInfo?.eventdates[0].epochtime)}</Col></Row>
                    
                    <Row className="p-t-25"><Col className="font-size-100">{eventInfo?.eventdescription}</Col></Row>
                    <Row className="p-t-25"><Col></Col></Row>
                </Col>

                <Col lg={1} >
                    <Row className="font-size-75">
                        <Col>
                            <Row className="font-heavy9 p-b-10">
                                <Col>  <FontAwesomeIcon icon={faLocationDot} />&nbsp;&nbsp;Location</Col>
                            </Row>
                            <Row>
                                <Col>{venue?.venuename}<br />
                                {venue?.venueaddress?.address1}<br />
                                {venue?.venueaddress?.city}, {venue?.venueaddress?.state} {venue?.venueaddress?.zip}</Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row className="p-t-50 font-size-75">
                        <Col>
                            <Row className="font-heavy9 p-b-10">
                                <Col>  <FontAwesomeIcon icon={faFile} />&nbsp;&nbsp;Refund Policy</Col>
                            </Row>
                            <Row>
                                <Col>Refunds up to 30 days before event</Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
}
export default EventInfo;

