import React from 'react';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*COMPONENTS */
import TopHeader from '../components/ui/TopHeader';
import EventLinkManagementContent from '../components/friend/eventlinkmanagementcontent';


function EventLinkManagement(){
    return(
        <React.Fragment>
            <Row>
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-25">
               
                <Col>
                    <EventLinkManagementContent />
                </Col>
                
            </Row>
        </React.Fragment>
    );
}
export default EventLinkManagement;