function base(){
    const baseurl="http://localhost:1110";
    return baseurl;
}

//export const orgurl="http://localhost:8600"; //LOCAL
//export const orgurl="http://198.200.106.4:8600"; //LOCAL
export const orgurl="https://alb.evntxz.com:8600"; //AWS

//export const apiurl="http://localhost:1110";//LOCAL
//export const apiurl="http://198.200.106.4:1110";//LOCAL
export const apiurl="https://alb.evntxz.com:1110"; //AWS

//export const commurl="http://localhost:1130";//LOCAL
//export const commurl="http://198.200.106.4:1130";//LOCAL
export const commurl="https://alb.evntxz.com:1130"; //AWS

//export const rtcom="http://localhost:8081";//LOCAL
//export const rtcom="http://198.200.106.4:8081";//LOCAL
export const rtcom="https://alb.evntxz.com:8081"

//export const accpakurl="http://localhost:1140/api/apm";//LOCAL
export const accpakurl="https://alb.evntxz.com:1140/api/apm"; //AWS

//export const billcapurl="http://localhost:1150"; //LOCAL
export const billcapurl="https://alb.evntxz.com:1150"; //AWS

//export const conurl="http://localhost:8100"
export const conurl="https://alb.evntxz.com:8100"; //AWS

//export const producturl ="http://localhost:8500"
//export const producturl ="http://198.200.106.4:8500"
export const producturl="https://alb.evntxz.com:8500"; //AWS

//export const loginurl="https://evntxz.b2clogin.com/evntxz.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_evntxz&client_id=da12b6a1-fcb9-4403-a3a7-53513e53713c&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Floginredirect&scope=openid&response_type=id_token&prompt=login";
export const loginurl="https://evntxz.b2clogin.com/evntxz.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_evntxz&client_id=da12b6a1-fcb9-4403-a3a7-53513e53713c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdpqflre1szf5k.cloudfront.net%2Floginredirect&scope=openid&response_type=id_token&prompt=login";

export default base;