import React,{useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import {dateFormat} from '../util/DateFormatter';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, producturl, rtcom}  from '../../environment';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faQrcode, faEnvelope, faBox, faBellConcierge, faList, faShare} from '@fortawesome/free-solid-svg-icons';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function ListEventTicketsContent(){
    const history = useNavigate();

    const[event, setEvent]=useState(null);
    const[tickets, setTickets]=useState(null);
    const[showQRCode, setShowQRCode]=useState(false);
    const[modalTicketInfo, setModalTicketInfo]=useState(null);

    const[showEmailEvent, setShowEmailEvent]=useState(false);
    const[showPTModelEvent, setShowPTModelEvent]=useState(false);
    const[emailAddress, setEmailAddress]=useState(null);
    const[currentTokenCount, setCurrentTokenCount]=useState(null);
    const[allTickets, setAllTickets]=useState([]);
    const[ticketAvailable, setTicketAvailable]=useState(true);
    const[transferToken, setTransferToken]=useState(null);
    const[currentToken, setCurrentToken]=useState(null);
    const[linkAccounts, setLinkAccounts]=useState([]);
    const[purchaseUserId, setPurchaseUserId]=useState(null);

    



    const handleTerminateDisplayEmailEventl=()=>{setShowEmailEvent(false)}
    const handleTerminateDisplayTPModel=()=>{setShowPTModelEvent(false)}

    
    const handleDisplayPTModel=(e)=>{
        setShowPTModelEvent(true);
        setModalTicketInfo(e);
    }

    const handleDisplayEmailEvent=(e)=>{
        setShowEmailEvent(true);
        setModalTicketInfo(e);
    }

    const handleSendPTForm=(t)=>{
        t.preventDefault();
        const userdata=JSON.parse(localStorage.getItem('userdata'));
   
        //GET TICKET PURCHASE
        new RestServer().setUrl(conurl+'/api/cms/getpurchaseticket/'+userdata.userid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            var data={
                endUserId:userdata.userid,
                productId:modalTicketInfo.ticketInfo.productId
            }
    
            new RestServer().setUrl(conurl+'/api/cms/getvendortokens')
            .setMethod('POST')
            .flagReturnData(true)
            .setPostBody(data)
            .connect()
            .then(response=>{
                var token=[];
                token=response.data;
                var currentToken=token.slice(-1);

                const dataTokenTransfer={
                    lastVendorToken:currentToken[0],
                    orginalPurcaseToken:modalTicketInfo,
                    amountToTransfer:transferToken,
                    transferTokenToEmail:emailAddress 
                };

                new RestServer().setUrl(conurl+'/api/cms/transfertoken')
                .setMethod('POST')
                .flagReturnData(true)
                .setPostBody(dataTokenTransfer)
                .connect()
                .then(response=>{
                    toast(response.data.message);
                    handleTerminateDisplayEmailEventl();    
                });
            
      
            },[]);
            
        },[]);

    }

    const handleAddForm = (e)=>{
        e.preventDefault();
  
        const sentTicketInfo={
            isPartOfNetwork:true,
            isPartOfFriend:false,
            contactInfo:{
                emailaddress:emailAddress
            }
        };

       const tinfo={
            internalTicketId:modalTicketInfo.ticketInfo.internalTicketId,
            ticketQRInfo:modalTicketInfo.ticketInfo.ticketQRInfo,
            ticketType:modalTicketInfo.ticketInfo.ticketType,
            productName:modalTicketInfo.ticketInfo.productName,
            productDescription:modalTicketInfo.ticketInfo.productDescription,
            productId:modalTicketInfo.ticketInfo.productId,
            ticketIsShared:modalTicketInfo.ticketIsShared,
            dateShared:modalTicketInfo.ticketInfo.dateShared,
            ticketSentToInfo:sentTicketInfo,
            ticketIsClaimed:modalTicketInfo.ticketInfo.ticketIsClaimed
        };

        var ticetsInfo=[];
        ticetsInfo.push(tinfo);

        const data={
            userId:purchaseUserId,
            purchaseTicketId:modalTicketInfo.purchaseTicketId,
            orderInfo:modalTicketInfo.orderInfo,
            ticketInfomation:ticetsInfo
        }
      
        new RestServer().setUrl(conurl+'/api/cms/sendticket')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
           
           toast(response.data.message);
            handleTerminateDisplayEmailEventl();    
        });
    }


    const routeClick=(path)=>{
        history(path, {replace:true})
    }


    const  setRedeemServiceIcon=(ti)=>{
        const obj1=<FontAwesomeIcon icon={faQrcode} className="clr-primary-2" />;
        const obj2=<FontAwesomeIcon icon={faBellConcierge} className="clr-primary-2" />;

        if(ti.ticketType==="EVNT"){
            return obj1
        }else{
            return obj2;
        }
    }

    const setRedeemServiceTxt=(ti)=>{
      var obj1="Redeem";
      var obj2="Request Service";

      if(ti.ticketType==="EVNT"){
        return obj1
      }else{
        return obj2
      }


    }
    const getTicketInfoName=(standardTicket, ticketinfo, getDataType)=>{
        var rtndata=null;
        if(ticketinfo.ticketType ==="PROD"){
            if(getDataType ==="NAME"){
                rtndata=ticketinfo?.productName;
            }
            if(getDataType==="DESCRIPTION"){
                rtndata=ticketinfo?.productDescription?.substring(0, 200)+"..."
            }

        }else{
            rtndata=standardTicket
        }
        return rtndata 
    }

    const crop=(e)=>{
        var descripholder =  e;
        var description=null;
        if(descripholder?.length <=200){
            description=descripholder;
        }else{
            description=descripholder?.substring(0,100)+"..."
        }

        return description;
    }


 
    const getRemoteData=(url)=>{
        return new Promise((resolve, reject)=>{
            fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
        })
    }

    const isActive=(e)=>{

        //return dateFormat.eventCompleted(e.eventdates[0].epochtime);
        return true;
    }

   

    const linkedAccounts=(linkGroupId)=>{
        //GET TICKET PURCHASE
        new RestServer().setUrl(conurl+'/api/cms/getlinkaccounts/'+linkGroupId)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
           const la=res.data;
           setLinkAccounts(la);
        },[]);
   }

    //get event ticket
    const handleEventTickets=()=>{

        const urlData = window.location.pathname.split("/");
        const userdata=JSON.parse(localStorage.getItem('userdata'));
       // setUserData(userdata);

        //GET TICKET PURCHASE

        new RestServer().setUrl(conurl+'/api/cms/getpurchaseticket/'+userdata.userid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            const purchaseTicket=res.data;
            var tempEventTickets=[];
           
            purchaseTicket?.map((p)=>{
                if(p?.orderInfo?.purchase.products[0].eventId === urlData[2]){
                    setPurchaseUserId(p.userId)
                    p?.ticketInfomation.map((t)=>{
                        linkedAccounts(p.orderInfo.orderid+urlData[2]);
                        var tic={
                            purchaseTicketId:p.purchaseTicketId,
                            orderInfo:p.orderInfo,
                            ticketInfo:t
                        }
                        tempEventTickets.push(tic);
                    }) 
                }
            },[]);
            setTickets(tempEventTickets);
        },[]);
    }

    const handleTerminateQRCodeModal=()=>{setShowQRCode(false)}

    const isTicketType=(t)=>{
        var rtn=false;

        if(t.ticketType==="VNDPROD"){
            rtn=true;
        }

        return rtn;
    }

    const vendorProd=(t)=>{
        var rtn=false;
        if(t.ticketInfo.ticketType==="VNDPROD"){
            rtn=true
        }
        return rtn;
    }
    
    const calVendorTicket=(t)=>{
        const userdata=JSON.parse(localStorage.getItem('userdata'));

        var data={
            endUserId:userdata.userid,
            productId:t.ticketInfo.productId
        }

        new RestServer().setUrl(conurl+'/api/cms/getvendortokens')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            var token=[];
            token=response.data;
            var currentToken=token.slice(-1);
            setCurrentTokenCount(currentToken[0].currentTokenCount);
        },[]);

    }

    const handleDisplayQRCode=(t)=>{
        setShowQRCode(true);
        setModalTicketInfo(t)
    }

    useEffect(() => {
         const urlData = window.location.pathname.split("/");
         
         //GET TICKET PURCHASE
        new RestServer().setUrl(producturl+'/api/prod/event/'+urlData[2])
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            setEvent(res.data);
            handleEventTickets();
                
     
        },[]);
    },[]);

    return(
        <React.Fragment>
            <Row className="p-b-25">
                <Col className="font-size-150 font-heavy8"> My Event</Col>
            </Row>
            <Row>
                <Col lg={10} sm={12} xs={12} md={10}>
                    <Row className="glass-container-2 p-10" >
                        <Col lg={3} sm={12} xs={12} md={10} ><img src={event?.eventimage[0].imageloc+event?.eventimage[0].imagename} className="img-container-1 p-b-20" /> </Col>
                        <Col >
                            <Row><Col className="font-size-150 font-heavy8">{event?.eventname}</Col></Row>
                            <Row><Col className="p-t-5 font-size-100 clr-1 font-heavy8">{dateFormat.getformateddate(event?.eventdates[0].epochtime)} - {dateFormat.eventCompleted(event?.eventdates[0].epochtime)?"CLOSED":""}</Col></Row> 
                            <Row><Col className="p-t-10 font-size-100">{event?.eventdescription}</Col></Row>
                                       
                            <Row className="p-t-75">
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{routeClick("/tickets")}}>
                                        <FontAwesomeIcon icon={faList} />
                                        <span className="icon-container-1-title">List Events
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>         
                                </Col>
                                <Col className="right">
                                    <button className="icon-container-1" onClick={()=>{routeClick("/eventlinkmanagement/"+event?.eventId)}}>
                                        <FontAwesomeIcon icon={faBox} />
                                        <span className="icon-container-1-title">Event Products<div className="icon-container-1-title-pointer"></div></span>
                                    </button>
                                </Col>
                                
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="p-t-50 p-b-25">
                <Col className="font-size-150 font-heavy8">Tickets</Col>
            </Row>
            <Row>
                {
                    tickets?.map((t)=>{
                        return<Col lg={2} className="glass-container-1 m-r-50 m-b-50">
                            <Row className="p-t-10">
                                <Col  onClick={()=>{}}>
                                    <FontAwesomeIcon icon={faQrcode} size="10x" />
                                </Col>
                            </Row>
                            <Row className="p-t-25 font-size-100 font-heavy8 p-l-10 p-r-10">
                               <Col className="font-size-150">{t?.ticketInfo.productName}</Col>
                            </Row>
                            {
                                ((vendorProd(t))? <Row className="p-t-10 font-size-100 p-l-10  font-heavy8  p-r-10 p-b-25">
                                <Col>{currentTokenCount} Tickets Remaining {calVendorTicket(t)}</Col>
                            </Row>:"")
                            }
                           
                            <Row className="p-t-10 font-size-75 p-l-10  p-r-10 container-h-100">
                               <Col>{crop(t?.ticketInfo.productDescription)}</Col>
                            </Row>

                            {dateFormat.eventCompleted(event?.eventdates[0].epochtime)?"":<Row className="p-t-75 p-b-25 p-l-10 p-r-10">
                                
                                
                                <Col>
                                    <button className="icon-container-1"  onClick={()=>{handleDisplayQRCode(t)}} >
                                        <FontAwesomeIcon icon={faQrcode} />
                                        <span className="icon-container-1-title">QR Code
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>
                                </Col>
                                {isTicketType(t.ticketInfo)?<Col>
                                    <button className="icon-container-1"  onClick={()=>{handleDisplayPTModel(t)}}>
                                        <FontAwesomeIcon icon={faShare} />
                                        <span className="icon-container-1-title">Send Ticket
                                            <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                    </button>
                                </Col>:<Col>
                                    <button className="icon-container-1"  onClick={()=>{handleDisplayEmailEvent(t)}}>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <span className="icon-container-1-title">Send Ticket
                                            <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                    </button>
                                </Col>}
                                <Col>
                                    <button className="icon-container-1">
                                        <FontAwesomeIcon icon={faBox} />
                                        <span className="icon-container-1-title">Add Watchlist
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>
                                </Col>
                            </Row>}

                            
                           


                        </Col>
                    })
                }
            </Row>
            
             {/*QR Code Modal*/}
             <Modal show={showQRCode} onHide={handleTerminateQRCodeModal}>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col className="m-10">
                            <img src={modalTicketInfo?.ticketInfo?.ticketQRInfo?.imgLoc} className="img-container-1" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={handleTerminateQRCodeModal}>
                        Close
                    </Button>
                    </Modal.Footer>
            </Modal>

             {/*Switch User Modal */}
             <Modal show={showEmailEvent} onHide={handleTerminateDisplayEmailEventl}>
                <Form onSubmit={handleAddForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Send Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="emailaddress" onChange={(s)=>{setEmailAddress(s.target.value)}}>
                                    <Form.Control className="clr-black" type="text" placeholder="Email Address" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleTerminateDisplayEmailEventl}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Send Ticket P
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

             {/*Sent partical ticket model */}
             <Modal show={showPTModelEvent} onHide={handleTerminateDisplayTPModel}>
                <Form onSubmit={handleSendPTForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Send Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="transferToken" onChange={(s)=>{setTransferToken(s.target.value)}}>
                                    <Form.Control className="clr-black" type="text" placeholder="Transfer Token" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="emailaddress" onChange={(s)=>{setEmailAddress(s.target.value)}}>
                                    <Form.Control className="clr-black" type="text" placeholder="Email Address" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleTerminateDisplayTPModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Send Ticket S
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </React.Fragment>
    );
}
export default ListEventTicketsContent;