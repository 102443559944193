import {useEffect} from 'react';
import { useNavigate } from "react-router-dom";


function Logout(){
    const history = useNavigate();

    useEffect(() => {
        localStorage.setItem("userdata", null);
        history('/', {replace:true})
    });

}
export default Logout;