import React, {useEffect} from 'react';
import './App2.css';
import EvntxzMain from './EvntxzMain';

function App() {

  useEffect(()=>{
  });

  return (
      <React.Fragment>
        <EvntxzMain />
      </React.Fragment>
  );
}

export default App;
