import React,{useState, useEffect} from 'react';
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl,producturl, orgurl}  from '../../environment';
import {dateFormat} from '../util/DateFormatter';
import {cartfunctions} from '../util/cartFunctions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShareSquare, faFileCirclePlus, faInfoCircle, faQrcode, faCartShopping, faSquareCheck, faSquare} from '@fortawesome/free-solid-svg-icons';

//STORES
import cartDataStore from '../../stores/zcartdatastore';

function TicketDates(){
const [tickets, setTickets]=useState([]);
const [selectedTicket, setSelectedTicket]=useState({});
const [selectedDate, setSelectedDate] = useState({});
const [dates, setDates]=useState([]);
const [howManyTickets, setHowManyTickets]=useState('');
const [showTicketModal,setShowTicketModal]=useState(false);
const [cart, setCart] = useState(null);
const [event, setEvent]=useState({});
const [venue, setVenue]=useState({});
const [cartProducts, setCartProducts]=useState([]);
const[priceRange, setPriceRange]=useState([0,0]);
const[ticketFilterAll, setTicketFilterAll]=useState([]);
const[ticketFilterFree, setTicketFilterFree]=useState([]);
const[ticketFilterGeneral, setTicketFilterGeneral]=useState([]);
const[ticketFilterVip, setTicketFilterVip]=useState([]);
const[ticketFilterPremium, setTicketFilterPremium]=useState([]);

const {addCartData} = cartDataStore();
const[selectedFilter, setSelectedFilter]=useState(0);

const filter=(id)=>{
    //TODO: add actual filter code
    setSelectedFilter(id)
    if(id===0){setTickets(ticketFilterAll);}
    if(id===1){setTickets(ticketFilterFree);}
    if(id===2){setTickets(ticketFilterGeneral);}
    if(id===3){setTickets(ticketFilterVip);}
    if(id===4){setTickets(ticketFilterPremium);}

}


const handleAddTicket=(e, i)=>{
    var cartD={};
    var cprod=[];
    
    if(cart===null){       

        const product={
            productcategoryid:e.category.categoryid,
            productcategorytype:e.category.categorycode,
            venueid:e.venueid,
            eventId:e.eventId,
            productid:e.productId,
            ticketIndexID:i,
            taxesId:e.taxesId,
            quanity:1,
            isTaxable:e.taxable
        }
        cprod.push(product);

        cartD={
            products:cprod
        }   
    }else{
        cprod=cart.products;
        const product={
            productcategoryid:e.category.categoryid,
            productcategorytype:e.category.categorycode,
            venueid:e.venueid,
            productid:e.productId,
            eventId:e.eventId,
            ticketIndexID:i,
            taxesId:e.taxesId,
            quanity:1,
            isTaxable:e.taxable
        }
        cprod.push(product);

        cartD={
            active:cart.active,
            activetil:cart.activetil,
            cartId:cart.cartId,
            datecreated:cart.datecreated,
            products:cprod,
            userid:cart.userid
        }
    }

      //THIS would add to chart
    
      new RestServer().setUrl(conurl+'/api/cms/addtocart')
      .setMethod('POST')
      .flagReturnData(true)
      .setPostBody(cartD)
      .connect()
      .then(response=>{
        const rtndata =response.data;

        if(rtndata.active){
          toast("Ticket is Added Successfully to Cart");
          setCart(rtndata);
          localStorage.setItem("usercart", JSON.stringify(rtndata));
        }else{
          toast("Ticket was not added please try again at a later time");
        }
    },[]);
}


useEffect(() => {

    //EVENT INFO
    //localStorage.setItem("usercart", null);
    var pr=[];

    const urlData = window.location.pathname.split("/");
    new RestServer().setUrl(producturl+'/api/prod/producteventid/'+urlData[2])
    .setMethod('GET')
    .flagReturnData(true)
    .connect()
    .then(response=>{
        
        const ticketsInfo=response.data;
        console.info("event info");
        console.info(ticketsInfo);
        //this is to set the ticket information
        var ticketAll=[];
        var ticketFree=[]
        var ticketGeneral=[];
        var ticketVip=[];
        var ticketPremium=[];

        

        


        ticketsInfo?.map((r)=>{
            ticketAll.push(r);

            if(r?.metadata?.map((m)=>{
                if(m.key==="proddetailfilter"){
                    if(m.value==="FREE"){ticketFree.push(r)}
                    if(m.value==="GENERAL"){ticketGeneral.push(r)}
                    if(m.value==="VIP"){ticketVip.push(r)}
                    if(m.value==="PREMIUM"){ticketPremium.push(r)}
                }
            }));
            setTickets(ticketAll);
            setTicketFilterAll(ticketAll);
            setTicketFilterFree(ticketFree);
            setTicketFilterGeneral(ticketGeneral);
            setTicketFilterVip(ticketVip);
            setTicketFilterPremium(ticketPremium);

            
            
            if(pr.length===0){
                pr[0]=r.price;
                pr[1]=r.price
            }else{
                if(r.price<pr[0]){pr[0]=r.price}
                if(r.price>pr[1]){pr[1]=r.price}
            }
            

        })
        setPriceRange(pr);
        //setDates(proddata.productdates);
       
        setCart(JSON.parse(localStorage.getItem('usercart')));
    });

    
},[]);

    
    return(
        <React.Fragment>
             <Row className="p-b-5">
                <Col lg={1} className="font-size-150 font-heavy8 p-b-25"> Tickets</Col>
                <Col>
                <Row >
                <Col>
                    <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>

                    {
                        (selectedFilter===0)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(0)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;All</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(0)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;All</button>
                    }
                     {
                        (selectedFilter===1)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(1)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;Free</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(1)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;Free</button>
                    }
                     {
                        (selectedFilter===2)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(2)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;General</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(2)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;General</button>
                    }
                     {
                        (selectedFilter===3)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(3)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;VIP</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(3)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;VIP</button>
                    }
                     {
                        (selectedFilter===4)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(4)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;Premium</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(4)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;Premium</button>
                    }

                 </Col>
            </Row>
                </Col>
            </Row>
        
             <Row>
                     <Col>
                     <Row className="p-t-25 p-b-25">
                       {
                           tickets?.map((ticket, index)=>{
                               return <Col lg={3} className="glass-container-1 m-r-50 m-b-50">
                                    <Row>
                                        <Col lg={2} className="font-size-300 font-heavy9"> <FontAwesomeIcon icon={faQrcode} /></Col>
                                        <Col className="font-size-150 font-heavy8 p-t-20">{ticket?.productName}</Col>
                                    </Row>
                                    
                                    <Row className="p-t-10"><Col className="font-size-75 font-heavy8">{dateFormat.getDDDHSSA(ticket?.additionalInfo?.eventsdata?.epochtime)}</Col></Row>
                                    <Row className="p-t-25">
                                        <Col className="font-size-75 font-light clr-primary-3" >{ticket?.productDescription}</Col>
                                    </Row>
                                    <Row className="p-t-25 font"><Col className="font-size-125 font-heavy9">${ticket.price}</Col></Row>

                                    <Row className="p-t-50 p-b-25 p-r-10">
                                        <Col>
                                            <button className="icon-container-1" onClick={()=>{handleAddTicket(ticket, index)}}>
                                                <FontAwesomeIcon icon={faCartShopping} />
                                                <span className="icon-container-1-title">Add to Cart
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>
                                        </Col>
                                        <Col>
                                            <button className="icon-container-1" >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                <span className="icon-container-1-title">Additional Info
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>
                                        </Col>
                                        <Col>
                                            <button className="icon-container-1" >
                                                <FontAwesomeIcon icon={faShareSquare} />
                                                <span className="icon-container-1-title">Share
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>
                                        </Col>
                                        <Col>
                                            <button className="icon-container-1">
                                                <FontAwesomeIcon icon={faFileCirclePlus} />
                                                <span className="icon-container-1-title">Add to Watchlist
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>
                                        </Col>
                                      

                                    
                                       
                                    </Row>

                                </Col>
                               
                           })
                       }
                    </Row>
                    </Col>
                    <Col lg={1}></Col>
                </Row>



        </React.Fragment>
    );
}
export default TicketDates;

