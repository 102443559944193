import React from 'react';
import logo from './logo.png';
import './logo.css'

//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Logo(){
    return(
        <React.Fragment>
            <Row>
                <Col><img src={logo} className="logo" /></Col>
            </Row>
        </React.Fragment>
    );
}
export default Logo;