import React from 'react';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*COMPONENTS */
import TopHeader from '../components/ui/TopHeader';
import PrivacyPolicyContent from '../components/content/privacypolicycontent';
import Footer from '../components/ui/footer';

function PrivacyPolicy(){
    return(
        <React.Fragment>
            <Row className="bck-clr-primary-0">
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-25  p-l-100">
                <Col><PrivacyPolicyContent /></Col>
            </Row>
            <Row className="bck-clr-primary-0">
                <Col lg={12} className="bottom"><Footer /></Col>
            </Row>
        </React.Fragment>
    );
}
export default PrivacyPolicy;