import React,{useState, useLayoutEffect} from 'react';

//INTERNAL
import TopHeader from '../components/ui/TopHeader';
import ListFavorites from '../components/favorites/listfavorites';
import Footer from '../components/ui/footer';

import RestServer from '../components/cRestServer';
import {producturl, conurl}  from '../environment';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart, faSignOutAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

function Favorites(){

    
    const userdata =JSON.parse(localStorage.getItem('userdata'));

    


    return(
        <React.Fragment>
        <Row>
            <Col><TopHeader /></Col>
        </Row>
        <Row className="p-t-25">
            
            <Col>
                <ListFavorites />
            </Col>
            
        </Row>
     
    </React.Fragment>
    );
}
export default Favorites;