import { toast } from "react-toastify";

export default class RestServer{

    constructor(){
        //console.info("RestServer");
        this.rtndata=false;
        this.redirect=null;
        this.body=null;
        this.url=null;
        this.method=null;
        this.data=null;
        this.redirect=null;
        this.header={
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin': '*'
        }

    }

    setUrl(url){
        this.url=url;
        return this;
    }

    setHeader(header){
        this.header=header;
        return this;
    }
    setMethod(method){
        this.method=method.toUpperCase();
        return this;
    }

    setRedirect(redirect){
        this.redirect=redirect;
        return this;
    }
    flagReturnData(flag){
        const isbool = typeof flag ==='boolean'? flag:toast("Must Be Type of Boolean");
        if(typeof flag ==='boolean'){
            this.rtndata=isbool;
        }
        return this;
    }
    setPostBody(body){
        this.body= JSON.stringify(body);
        return this;
    }
    
    connect(){
       // console.info("Connect");
        return new Promise((resolve, reject)=>{
           // console.info("Connect->promise");
            //console.info("Connect->promise->this.url:"+ this.url);
           // console.info("Connect->promise->this.body:"+ this.body);
            fetch(this.url, {
                method: this.method,
                headers: this.header,
                body:  this.body
                }).then(response => {
                   return response.json();
                }).then( data=>{
                    
                    if(this.rtndata){
                        this.data=data;
                       resolve(this);
                    }else{
                        if(data.messageTypeID===0){
                            toast(data.message);
                            reject(data.message);
                        }
                    }
                   
                    if(this.redirect!==null){
                        window.location.href=this.redirect;
                    }
                }).catch((error) => {
                    reject(error);
                  //console.log(error);
            });
            
        }
        )
        
    }

    getData(){
        console.info( this.data)
        return this;
    }

}