import React from 'react';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*COMPONENTS */
import TopHeader from '../components/ui/TopHeader';
import LoginForm from '../components/login/loginform';
import Footer from '../components/ui/footer';




function Login(){

    return(
        <React.Fragment>
             <Row>
                <Col><TopHeader /></Col>
            </Row>
            
            <Row className="p-t-25">
                <Col><LoginForm /></Col>
            </Row>
            <Row className="anchor_bottom">
                <Col lg={12}><Footer /></Col>
            </Row>
        </React.Fragment>
    );
}
export default Login;