
import { useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RestServer from '../../components/cRestServer';
import {faTicket, faShareSquare, faCalendarCheck, faHeart, faEye} from '@fortawesome/free-solid-svg-icons';

function GenericIconModal(props){

    const history = useNavigate();

    const [show, setShow] = useState(false);
    const [userData, setUserData]=useState(null);

    const handleClose = () => {
        setShow(false);
    }
    
    const handleActionModel=()=>{
        history("/login", {replace:true})
    }

    const handleShow = () => {
        setShow(true);
    }

   

    const handleGenericAction=(data)=>{
      

        console.info("handleGenericAction->");
        console.info("userData:")
        console.info(userData);

        console.info("data: ");
        console.info(data);

        if(userData===null){
            setShow(true);
        }else{
            var postdata;
            if(data.type==="fav"){
                postdata={
                    producttype:"EVENT",
                    productid:props.prodid,
                    usertype:"EndUser",
                    userid:userData.userid
                }
            }
            
            new RestServer().setUrl(data.url)
                .setMethod('POST')
                .flagReturnData(true)
                .setPostBody(postdata)
                .connect()
                .then(response=>{
                    const res= response.data;
                    if(res.messageTypeID===1){
                        toast("Event was added to you favorites");
                    }
            },[]);
            
        }


    }
    useEffect(() => {
        const userData= localStorage.getItem('userdata');
        setUserData(JSON.parse(userData));
    },[]);

    return(
        <React.Fragment>

            <button className="icon-container-1" onClick={()=>{handleGenericAction(props.data.postData)}}>
                                                <FontAwesomeIcon icon={props.data.icon} />
                                                <span className="icon-container-1-title">{props.data.iconName}
                                                    <div className="icon-container-1-title-pointer"></div>
                                                </span>
                                            </button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.data.modalHeader}</Modal.Title>
                </Modal.Header>
                    <Modal.Body>{props.data.modalText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {props.data.closeModal}
                    </Button>
                    <Button variant="primary" onClick={handleActionModel}>
                        {props.data.actionModal}
                    </Button>
                </Modal.Footer>
            </Modal>
         
        </React.Fragment>
    );
}
export default GenericIconModal;