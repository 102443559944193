import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";


/*BOOTSTRAP*/
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';



//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, loginurl}  from '../../environment';


function LoginForm(){

    /*
    const [username, setUsername]=useState([]);
    const [password, setPassword]=useState([]);

    const history = useNavigate();

    const handleLogin =(e)=>{
        e.preventDefault();
        const data={
            username:username,
            password:password
        }
        new RestServer().setUrl(conurl+'/api/cms/login')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            console.info(rtndata);
            localStorage.setItem("userdata", JSON.stringify(rtndata));

           const urlredirect= localStorage.getItem('loginredirect');
            history(urlredirect, {replace:true})
        },[]);
    }
     */
    useEffect(() => {

        //window.location.href=loginurl;
    });
   

    return(
        <React.Fragment>
            {/*
            <Row className="p-l-75 p-r-100 p-t-200 p-b-200">
                    <Col lg={4}></Col>
                    <Col className="shadow m-10 rad-25" lg={4}>
                        <Row className="p-t-25 m-10"><Col className="font-200 center">LOG IN</Col></Row>
                        <Row className="p-t-25 m-10">
                            <Col className="m-10">
                                <Row className="p-t-10">
                                    <Form.Control 
                                    onChange={(s)=>{setUsername(s.target.value)}}
                                    className="clr-black" type="text" placeholder="Username" />
                                </Row>
                                <Row className="p-t-10">
                                    <Form.Control
                                    onChange={(s)=>{setPassword(s.target.value)}}
                                    className="clr-black" type="password" placeholder="Password" />
                                </Row>
                            </Col>
                        </Row>
                        <Row className="p-t-10 m-10">
                            <Col lg={1}><Form.Check type="checkbox" label="" /></Col>
                            <Col>Keep me signed in</Col>
                            <Col>Forgot?</Col>
                        </Row>

                        <Row className="p-t-25 p-b-25 m-10 txt-center"> 
                            <Col> <Button variant="danger" onClick={handleLogin}>LOGIN</Button>{' '}</Col>
                            <Col><Button variant="danger">CANCEL</Button>{' '}</Col>
                            
                        </Row>
                    </Col>
                     <Col lg={4}></Col>
                </Row>
    */}
        </React.Fragment>
    );
}
export default LoginForm;