import React from 'react';
import { useNavigate } from "react-router-dom";

import "../../components/ui/footer.css";

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faPinterest } from "@fortawesome/free-brands-svg-icons"
import {faCopyright} from '@fortawesome/free-solid-svg-icons';

function Footer(){

    const history = useNavigate();

    const routeClick=(path)=>{
        history(path, {replace:true})
    }

    return (
        <React.Fragment>
            <Row className=" p-t-10">
                    <Col lg={1}></Col>
                    <Col lg={10}>
                        <Row className="p-t-25 p-b-25 p-l-75 font-size-75 font-heavy">
                            <Col>
                                <Row className="footer p-b-5"><a href="/aboutus">About</a></Row>
                                <Row className="footer p-b-5"><a href="/help">Help</a></Row>
                                <Row className="footer p-b-5"><a href="/support">Support</a></Row>
                                <Row className="footer p-b-5"><a href="/contactus">Contact</a></Row>
                            </Col>
                            <Col>
                                <Row><Col className="p-b-10 font-size-100 font-light">Newsletter Sign Up</Col></Row>
                                <Row>
                                    <Col><Form.Control className="clr-black" type="text" /></Col>
                                    <Col><Button variant="danger">Submit</Button>{' '}</Col>
                                </Row>
                            </Col>
                            {/*
                            <Col>
                                <Row className="p-b-10"><Col>Follow Us</Col></Row>
                                <Row>
                                    <Col xl={1}><FontAwesomeIcon icon={faFacebook}  className="font-250 clr-red"/> </Col>
                                    <Col xl={1}><FontAwesomeIcon icon={faInstagram}  className="font-250 clr-red" /></Col>
                                    <Col xl={1}><FontAwesomeIcon icon={faTwitter} className="font-250 clr-red" /></Col>
                                    <Col xl={1}><FontAwesomeIcon icon={faPinterest} className="font-250 clr-red" /></Col>
                                </Row>
                            </Col>
    */}
                        </Row>
                    </Col>
                    <Col lg={1}></Col>
                </Row>

                <Row className="p-t-10 p-b-10 bck-clr-light-grey">
                <Col lg={1}></Col>
                    <Col lg={10}>
                        <Row  className="p-l-75 font-size-75 topiconnav3">
                            <Col lg={1} className="footer"><a href="/privacypolicy">Privacy Policy</a></Col>
                            <Col lg={1} className="footer"><a href="/cookiepolicy">Cookie Policy</a></Col>
                            <Col lg={9} className="footer"><a href="/termsconditions">Terms And Conditions</a></Col>
                            <Col ><FontAwesomeIcon icon={faCopyright} />&nbsp;Copyright</Col>
                        </Row>
                    </Col>
                    <Col lg={1}></Col>
                </Row>

        </React.Fragment>
    );
}
export default Footer;