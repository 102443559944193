import React,{useState, useLayoutEffect} from 'react';

//INTERNAL
import TopHeader from '../components/ui/TopHeader';
import EventInfo from '../components/productdetail/eventinfo';
import TicketDates from '../components/productdetail/ticketdates';
import Comments from '../components/ui/comments';
import EventMap from '../components/productdetail/eventmap';
import Footer from '../components/ui/footer';

import RestServer from '../components/cRestServer';
import {producturl, conurl}  from '../environment';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart, faSignOutAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

function ProductDetail(){

    
    const userdata =JSON.parse(localStorage.getItem('userdata'));

    


    return(
        <React.Fragment>
            <Row>
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-25">
               
                <Col>
                    <Row className="p-b-25 p-t-25">
                        <Col><EventInfo /></Col>
                    </Row>
                    <Row className="p-t-50">
                        <TicketDates/>
                    </Row>


                    
                    <Row className=" line-primary-6-top-1 ">
                        <EventMap />
                    </Row>
                </Col>
                
            </Row>
            
        </React.Fragment>
    );
}
export default ProductDetail;