import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import {QrScanner} from '@yudiel/react-qr-scanner';

import {dateFormat} from '../util/DateFormatter';

//INTERNAL
import RestServer from '../cRestServer';
import {conurl, producturl, rtcom}  from '../../environment';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faList, faQrcode, faCamera, faFingerprint, faBagShopping, faPersonCircleExclamation, faUserMinus} from '@fortawesome/free-solid-svg-icons';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'



function EventLinkManagementContent(){
    const history = useNavigate();

    const[event, setEvent]=useState(null);

    const[scanData, setScanData]=useState(null);
    const[userName,setUserName]=useState(null);
    const[userEmail,setUserEmail]=useState(null);

    const[initScanUserDataModel, setInitScanUserDataModel]=useState(false);
    const[initScanModel, setInitScanModel]=useState(false);
    const[myIdModel,setMyIdModel]=useState(false);
    const[userData,setUserData]=useState(null);
    const[orderId, setOrderId]=useState(null);
    const[linkAccounts, setLinkAccounts]=useState([]);
   

    const handleCloseMyIdModel=()=>{
        setMyIdModel(false);
    }
    const handleCloseModel=()=>{
        setInitScanModel(false);
    }

    const handleCloseUserCloseModel=()=>{
        setInitScanUserDataModel(false);
    }

    const routeClick=(path)=>{
        history(path, {replace:true})
    }

    const processScanAndAdd=(resString)=>{
        const urlData = window.location.pathname.split("/");
        const userData = JSON.parse(localStorage.getItem('userdata'));

        const data={
            linkGroupId:orderId+urlData[2],
            linkerUserId:userData.userid,
            linkUserId:resString,
            eventId:urlData[2],
            isActive:true

        }
        setScanData(JSON.stringify(data));

        setInitScanUserDataModel(true);
        
    }

    const handleAddLinkAccount=()=>{
        const urlData = window.location.pathname.split("/");
        const orgData=JSON.parse(scanData);
        const data={
            linkAccountInternalId:orgData.linkAccountInternalId,
            linkerUserId:orgData.linkerUserId,
            eventId:orgData.eventId,
            isActive:orgData.isActive,
            linkName:userName,
            linkEmail:userEmail
        };

        new RestServer().setUrl(conurl+'/api/cms/linkaccount')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                history('/eventlinkmanagement/'+urlData[2], {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
    }


/*
    const handleGetEventProducts=(eid)=>{
        console.info("handleGetEventProducts->eid:");
        console.info(eid);
        new RestServer().setUrl(producturl+'/api/prod/ineventproducteventid/'+eid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            const prod=res.data;
            console.info("handleGetEventProducts->prod: ");
            console.info(prod);

            var inEventProducts=[];
            prod.map((p)=>{
                console.info("prod->p: ");
                console.info(p);
                if(p.category.categorycode==="INEVENTPRODUCT"){
                    console.info("INEVENTPRODUCT->p: ");
                    console.info(p)
                    inEventProducts.push(p);
                }
            })
            console.info("inEventProducts:");
            console.info(inEventProducts);
            setProducts(inEventProducts);
           // setEvent(eventdata);
        },[]);

    }


    
    const handleGetEventData=(eid)=>{
        console.info("handleGetEventData->product: ");
        console.info(eid);
        console.info("eid?.orderInfo.purchase.products[0].eventId :");
        console.info(eid?.orderInfo.purchase.products[0].eventId);
        new RestServer().setUrl(producturl+'/api/prod/event/'+eid?.orderInfo.purchase.products[0].eventId)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            const eventdata=res.data;
            console.info("handleGetEventData->product->eventdata: ");
            console.info(eventdata);
            handleGetEventProducts(eventdata.eventId)
            setEvent(eventdata);
            
        },[]);
    }
    */

    const linkedAccounts=(linkGroupId)=>{
         //GET TICKET PURCHASE
         new RestServer().setUrl(conurl+'/api/cms/getlinkaccounts/'+linkGroupId)
         .setMethod('GET')
         .flagReturnData(true)
         .connect()
         .then(res=>{
            const la=res.data;
            console.info("linkedAccounts");
            console.info(la);
            setLinkAccounts(la);
         },[]);
    }

     //get event ticket
     const getSetOrderId=()=>{
        console.info("getSetOrderId: ");

        const urlData = window.location.pathname.split("/");
        const userdata=JSON.parse(localStorage.getItem('userdata'));
       // setUserData(userdata);

        //GET TICKET PURCHASE
        new RestServer().setUrl(conurl+'/api/cms/getpurchaseticket/'+userdata.userid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            const purchaseTicket=res.data;
       
           
            purchaseTicket?.map((p)=>{
                if(p?.orderInfo?.purchase.products[0].eventId === urlData[2]){
                    console.info("purchaseTicket->p:");
                    console.info(p)
                    setOrderId(p.orderInfo.orderid);
                    linkedAccounts(p.orderInfo.orderid+urlData[2]);

                }
            },[]);
           
        },[]);
    }

    useEffect(() => {
        console.info("ListEventTicketsContent->useEffect");
        const userDataSource = JSON.parse(localStorage.getItem('userdata'));
        console.info("userDataSource :");
        console.info(userDataSource);

        setUserData(userDataSource);

         const urlData = window.location.pathname.split("/");
         
         //GET TICKET PURCHASE
        new RestServer().setUrl(producturl+'/api/prod/event/'+urlData[2])
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            console.info("res.data: ");
            console.info(res.data)
            setEvent(res.data);
            //handleGetEventProducts(urlData[2]);
            getSetOrderId();
                
     
        },[]);
    },[]);

    return(
        <React.Fragment>
                <Row className="p-b-25">
                <Col className="font-size-150 font-heavy8"> Event Link Management</Col>
            </Row>
            <Row>
                <Col lg={10} sm={12} xs={12} md={10}>
                    <Row className="glass-container-2 p-10" >
                        <Col lg={3} sm={12} xs={12} md={10} ><img src={event?.eventimage[0].imageloc+event?.eventimage[0].imagename} className="img-container-1 p-b-20" /></Col>
                        <Col >
                            <Row><Col className="font-size-150 font-heavy8">{event?.eventname}</Col></Row>
                            <Row><Col className="p-t-5 font-size-100 clr-1 font-heavy8">{dateFormat.getformateddate(event?.eventdates[0].epochtime)} - {dateFormat.eventCompleted(event?.eventdates[0].epochtime)?"CLOSED":""}</Col></Row> 
                            <Row><Col className="p-t-10 font-size-100">{event?.eventdescription}</Col></Row>
                                       
                            <Row className="p-t-75">
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{routeClick("/tickets")}}>
                                        <FontAwesomeIcon icon={faList} />
                                        <span className="icon-container-1-title">List Events
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>         
                                </Col>
                                <Col className="right">
                                    <button className="icon-container-1" onClick={()=>{routeClick("/listeventtickets/"+event?.eventId)}}>
                                        <FontAwesomeIcon icon={faQrcode} />
                                        <span className="icon-container-1-title">Event Tickets<div className="icon-container-1-title-pointer"></div></span>
                                    </button>
                                </Col>
                                
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="p-t-50 p-b-25">
                <Col lg={1} sm={4} xs={4}className="font-size-125 font-heavy8"> Link Users</Col>
                <Col lg={1} sm={4} xs={4}>
                    <button className="icon-container-1" >
                        <FontAwesomeIcon icon={faFingerprint}  onClick={()=>{setMyIdModel(true)}}/>
                        <span className="icon-container-1-title">My Id<div className="icon-container-1-title-pointer"></div></span>
                    </button>
                </Col>
                <Col lg={1} sm={4} xs={4}>
                    <button className="icon-container-1" >
                        <FontAwesomeIcon icon={faCamera}  onClick={()=>{setInitScanModel(true)}}/>
                        <span className="icon-container-1-title">Add Users<div className="icon-container-1-title-pointer"></div></span>
                    </button>
                </Col>
            </Row>
            <Row>
                {
                    linkAccounts?.map((a)=>{
                        return<Col lg={2} sm={10} xs={10} className="glass-container-1">
                        <Row className="p-t-10">
                            <Col className="m-r-25" lg={2}>
                                <FontAwesomeIcon icon={faUser} size="5x" />
                            </Col>
                            <Col>
                                <Row className="p-t-5 font-size-150 font-heavy p-l-10 p-r-10">
                                    <Col>{a.linkName}</Col>
                                </Row>
                                <Row className="p-t-10 font-size-75 p-l-10 p-r-10">
                                    <Col>Mar 14 2024@10:31AM - Token User at adssadasdasdas</Col>
                                </Row>
                            </Col>
                        </Row>
    
                        <Row className="p-t-50 p-b-25">
                            <Col>
                                <button className="icon-container-1" >
                                    <FontAwesomeIcon icon={faBagShopping} />
                                    <span className="icon-container-1-title">History
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>
                            </Col>
                            <Col>
                                <button className="icon-container-1" >
                                    <FontAwesomeIcon icon={faPersonCircleExclamation} />
                                    <span className="icon-container-1-title">Block and Alert
                                                <div className="icon-container-1-title-pointer"></div>
                                            </span>
                                </button>
                            </Col>
                            <Col>
                                <button className="icon-container-1" >
                                    <FontAwesomeIcon icon={faUserMinus} />
                                    <span className="icon-container-1-title">Remove User
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>
                            </Col>          
                        </Row> 
                    </Col>
                    })
                }
            </Row>

             {/*QR Code Modal*/}
             <Modal show={myIdModel} onHide={handleCloseMyIdModel}>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col className="m-10">
                            <img src={userData?.useridQRImage?.fileLocation+userData?.useridQRImage?.fileName} className="img-container-1" />
                            
                            </Col>
                        </Row>

                       
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={handleCloseMyIdModel}>
                        Close
                    </Button>
                    </Modal.Footer>
            </Modal>
            
            {/*Process Model*/}
            <Modal show={initScanModel} onHide={handleCloseModel}>
                <Form >
                    <Modal.Header closeButton>
                    <Modal.Title>Link User Scan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            
                            <QrScanner 
                                onDecode={(result) => processScanAndAdd(result)}
                                onError={(error) => alert(error?.message)}  
                            />
    
                            
                        </Row>
                    
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary"onClick={handleCloseModel} >
                        Cancel
                    </Button>
                
                    </Modal.Footer>
                </Form>
            </Modal>

              {/*Add User Data*/}
              <Modal show={initScanUserDataModel} onHide={handleCloseUserCloseModel}>
                <Form >
                    <Modal.Header closeButton>
                    <Modal.Title>Scan User Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="eventname" onChange={(s)=>{setUserName(s.target.value)}}>
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control className="clr-black" type="text" placeholder="event name" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="eventname" onChange={(s)=>{setUserEmail(s.target.value)}}>
                                    <Form.Label>User Email</Form.Label>
                                    <Form.Control className="clr-black" type="text" placeholder="event name" />
                                </Form.Group>
                            </Col>
                        </Row>
                    
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUserCloseModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleAddLinkAccount}>
                        Add Link Account
                    </Button>
                
                    </Modal.Footer>
                </Form>
            </Modal>

         
        </React.Fragment>
    );
}
export default EventLinkManagementContent;