import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Logo from '../ui/logo';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCartPlus, faCart, faLock, faUserPlus, faLockOpen, faCalendarCheck, faTicketAlt, faHeart, faHomeAlt} from '@fortawesome/free-solid-svg-icons';

import {loginurl}  from '../../environment';

import cartDataStore from '../../stores/zcartdatastore';


function TopHeader(){

    const { cartData} = cartDataStore();
    const history = useNavigate();
    var cartlength=0;
    
    
    const [userData, setUserData]=useState(null);
    const[redirect, setRedirect]=useState(null);

    const logout=()=>{
        localStorage.setItem("userdata", null);

        setUserData(null);
        localStorage.setItem("loginredirect", '/');

        history('/', {replace:true})

    }

    const routeClick=(path)=>{
        history(path, {replace:true})
    }

    const rendermenu =()=>{
        if(userData===null){
            return <Row className="right flex ">
                <Col>
                    <button className="icon-container-1" onClick={()=>{routeClick("/")}}>
                        <FontAwesomeIcon icon={faHomeAlt} />
                        <span className="icon-container-1-title">Home
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                </Col>
               <Col>
                    <button className="icon-container-1" onClick={()=>{routeClick("/cart")}}>
                        <FontAwesomeIcon icon={faCartPlus} />
                        <span className="icon-container-1-title">Cart
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                </Col>
                <Col>
                    <a href={loginurl}>
                        
                        <button className="icon-container-1">
                        <FontAwesomeIcon icon={faLockOpen} />
                        <span className="icon-container-1-title">Login
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                    </a></Col>
       </Row>
        }else{
            return <Row className="right flex">
                 <Col>
                    <button className="icon-container-1" onClick={()=>{routeClick("/")}}>
                        <FontAwesomeIcon icon={faHomeAlt} />
                        <span className="icon-container-1-title">Home
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                </Col>
                <Col>
                    <button className="icon-container-1" onClick={()=>{routeClick("/cart")}}>
                        <FontAwesomeIcon icon={faCartPlus} />
                        <span className="icon-container-1-title">Cart
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                </Col>
                <Col>
                    <button className="icon-container-1" onClick={()=>{routeClick("/favorites")}}>
                        <FontAwesomeIcon icon={faHeart} />
                        <span className="icon-container-1-title">Favorites
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                </Col>
                <Col>
                    <button className="icon-container-1" onClick={()=>{routeClick("/tickets")}}>
                        <FontAwesomeIcon icon={faTicketAlt} />
                        <span className="icon-container-1-title">Tickets
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                </Col>
               
                <Col>
                    <button className="icon-container-1" onClick={()=>{logout()}}>
                        <FontAwesomeIcon icon={faLock} />
                        <span className="icon-container-1-title">Logout
                            <div className="icon-container-1-title-pointer"></div>
                        </span>
                    </button>
                </Col>
           
       </Row>

        }
    }

    useEffect(() => {
        const urlData = window.location.pathname.split("/");
        var redirectURL=null;
        if(urlData[1] !=="login"){
            switch(urlData.length){
                case 2:
                    redirectURL="/"+urlData[1];
                    break;
                case 3:  
                    redirectURL="/"+urlData[1]+"/"+urlData[2];
                    break;
                default:
                    redirectURL="/"
            }
            localStorage.setItem("loginredirect", JSON.stringify(redirectURL));
        }

        setUserData(JSON.parse(localStorage.getItem('userdata')));
        cartlength=cartData?.products?.length;

      },[]);
    
    return(
        <React.Fragment>
           <Row className="line-lightgrey-1-bottom p-t-20 p-b-20">
               <Col className="logo-container"><a href="/"><Logo /></a></Col>
               
               <Col lg={8} md={5} sm={12} ca>&nbsp;</Col>
               <Col >
                   {rendermenu()}
                  
               </Col>
           </Row>
        </React.Fragment>
    );
}
export default TopHeader;