import React from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { CreditCard, PaymentForm} from 'react-square-web-payments-sdk';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//import MyPaymentForm from './paymenform';

import {conurl} from '../../environment';
import RestServer from '../cRestServer';


function CartProcess(){
    const cartProcessdata = JSON.parse(localStorage.getItem('cartpayData'));
    const userData = JSON.parse(localStorage.getItem('userdata'));
    const cdata = JSON.parse(localStorage.getItem('usercart'));

    const history = useNavigate();

    const purcahseProcess=(p)=>{
      console.info("purcahseProcess->p:");
      console.info(p);
      const orderModel ={
        //orderdate
        orderstatus:"PURCHASE",
        userid:p.user.userid,
        useremail:p.user.contact.emailaddress,
        purchase: p.cart,
        //purchaseid
        //ordertotal
  
      }
      //console.info("purcahseProcess:")
      //console.info(orderModel);
      
      new RestServer().setUrl(conurl+'/api/cms/purchase')
      .setMethod('POST')
      .flagReturnData(true)
      .setPostBody(orderModel)
      .connect()
      .then(response=>{
        const rtndata =response.data;
        if(rtndata.messageTypeID===1){
          localStorage.setItem("usercart", null);
          history('/', {replace:true})
        }else{
          toast(rtndata.Message);
        }
    },[]);
    

    }

  

    return(
        <React.Fragment>
            <Row>
                <Col lg={5} className="m-r-100">
                    <Row className="p-b-50"><Col className="font-size-150 font-heavy8">Payments</Col></Row>
                    <Row>
                        <Col>
                        <PaymentForm 
    applicationId="sandbox-sq0idb-jq-0j5GajngfLeBYTwEWOw"
    cardTokenizeResponseReceived={(token, buyer) => {
      //console.info({ token, buyer });

      const processcard = {
        cart:cdata,
        user:userData,
        buyer:buyer,
        token:token,
        amount:cartProcessdata.total
      }
      console.info("processtoken->processcard: ")
      console.info(processcard)


      
      new RestServer().setUrl(conurl+'/api/cms/processtoken')
      .setMethod('POST')
      .flagReturnData(true)
      .setPostBody(processcard)
      .connect()
      .then(response=>{
          const rtndata =response.data;
          //console.info("rtndata: ");
          //console.info(rtndata);
          if(rtndata.messageTypeID ===1){
            purcahseProcess(processcard);

          }
         
      });

    }}

    
    createVerificationDetails={() => ({

  

      amount: cartProcessdata.total,
      /* collected from the buyer - Not Needed*/
      billingContact: {
        countryCode: 'CA',
      },
      
      currencyCode: 'CAD',
      intent: 'CHARGE',
    })}

    locationId="LSKAZJFG8FTQM"
  >
    <CreditCard
        buttonProps={{
          css: {
            "[data-theme='dark'] &": {
              backgroundColor: '#00000',
              color: 'var(--ifm-color-emphasis-100)',
              '&:hover': {
                backgroundColor: '#0091ea',
              },
            },
            backgroundColor: '#000000',
            fontSize: '1rem',
            fontWeight:800,
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3C3C3C',
            },
          },
        }}
        style={{
          input: {
            fontSize: '14px',
          },
          'input::placeholder': {
            color: '#000000',
          },
        }}
    >VARIFY CARD</CreditCard >

    </PaymentForm>
                        
                        </Col>
                    </Row>
                </Col>
                <Col lg={4} className="m-l-100">
                    <Row>
                        <Col>
                            <Row className="p-b-25">
                              <Col className="font-size-150 font-heavy8"> Order Summary</Col>
                            </Row>
                            <Row>
                              <Col className="container-1 font-size-100 font-heavy">
                                <Row className="p-t-25">
                                    <Col className="p-l-50">Subtotal</Col>
                                    <Col className="right p-r-50">${cartProcessdata.subTotal}</Col>
                                </Row>
                                <Row className="p-t-10">
                                    <Col className="p-l-50">Surcharge</Col>
                                    <Col className="right p-r-50">{cartProcessdata.surcharge}</Col>
                                </Row>
                                <Row className="p-t-10">
                                    <Col className="p-l-50">Taxes</Col>
                                    <Col className="right p-r-50">{cartProcessdata.taxes}</Col>
                                </Row>
                                <Row className="p-t-25 font-heavy9 p-b-25">
                                    <Col className="p-l-50">Total</Col>
                                    <Col className="right p-r-50">${cartProcessdata.total}</Col>
                                </Row>
                              </Col>
                            </Row>
                        </Col>
    
                      </Row>
                    
                </Col>
            </Row>
            
    
        
        </React.Fragment>
    );
}
export default CartProcess;