import React from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function EventMap(){

    const containerStyle = {
      width: '100%',
      height: '500px',
      };

      const center = {
        lat: 47.444,
        lng: -122.176
      };

      const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAPITeJYnW53j2H4_w04b845Cz51PxnDp0"
      })

      const [map, setMap] = React.useState(null);

      const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
      }, [])
    

    return isLoaded ?(
    <React.Fragment>
       
      <Row className="p-t-25 p-b-25">
          <Col lg={12}>
              <Row className="p-t-25">
                <Col>
                  <span>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={14}
                      //onLoad={onLoad}
                      onUnmount={onUnmount}
                      >
                        <></>
                  </GoogleMap>
                </span>
              </Col>
            </Row>
          </Col>
      </Row>
    </React.Fragment>
    ): <></>
}
export default React.memo(EventMap)

