import { toast } from 'react-toastify';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, orgurl, producturl, loginurl}  from '../../environment';

export  const cartfunctions={

    getCart:function(){
        const cdata = JSON.parse(localStorage.getItem('usercart'));
        return cdata;

    },
    decToCart:function(data){
        const cdata = JSON.parse(localStorage.getItem('usercart'))
        var cartdata=null;

        //APPEND PRODUCT OR ADD
        var productsarr=[];
        if(cdata === null){
           
            productsarr.push(data);
            cartdata = {
                products:productsarr,
            }
        }else{
            var prodpush=true;
            cdata.products.map((prod)=>{
                if(data.productid==prod.productid){
                    prod.quanity=prod.quanity-1;
                    productsarr.push(prod);
                    prodpush=false;
                }else{
                    productsarr.push(prod);
                } 
            })

            if(prodpush){
                productsarr.push(data);
            }

            cartdata = {
                cartId: cdata.cartId,
                products:productsarr,
                active:cdata.active,
                datecreated:cdata.datecreated
            }
        }

        //UPDATE / CREATE CART
        return new RestServer().setUrl(conurl+'/api/cms/addtocart')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(cartdata)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            localStorage.setItem("usercart", JSON.stringify(rtndata));
        },[]);    

    },
    addToCart:function(data){
        const cdata = JSON.parse(localStorage.getItem('usercart'))
        var cartdata=null;
        console.info("addToCart->data:");
        console.info(data);

        //APPEND PRODUCT OR ADD
        var productsarr=[];
        if(cdata === null){
           
            productsarr.push(data);
            cartdata = {
                products:productsarr,
            }
        }else{
            var prodpush=true;
            cdata.products.map((prod)=>{
                if(data.productid==prod.productid){
                    prod.quanity=prod.quanity+1;
                    productsarr.push(prod);
                    prodpush=false;
                }else{
                    productsarr.push(prod);
                } 
            })

            if(prodpush){
                productsarr.push(data);
            }

            cartdata = {
                cartId: cdata.cartId,
                products:productsarr,
                active:cdata.active,
                datecreated:cdata.datecreated
            }
        }

        //UPDATE / CREATE CART
        return new RestServer().setUrl(conurl+'/api/cms/addtocart')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(cartdata)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            localStorage.setItem("usercart", JSON.stringify(rtndata));
        },[]);    

    },
    removeFromCart:function(data){

    },
    updateChart:function(data){

    },
    clearCart:function(){
        localStorage.setItem("usercart", null);
        
    }
};