import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

//INTERNAL
import RestServer from '../components/cRestServer';
import {conurl}  from '../environment';



function LoginRedirect(){
    const history = useNavigate();
    const[data, setData]=useState();

    useEffect(() => {
        const queryString = window.location.hash
        
        const urlParams = new URLSearchParams(queryString);
        const urltoken=urlParams.get('#id_token');

        if(urltoken ===null){
            //redirect them to home screen
            history("/", {replace:true})

        }else{

            const data={
                token:urltoken,
            }

            new RestServer().setUrl(conurl+'/api/cms/usertoken')
            .setMethod('POST')
            .flagReturnData(true)
            .setPostBody(data)
            .connect()
            .then(response=>{
                const rtndata =response.data;

               
                localStorage.setItem("userdata", JSON.stringify(rtndata));
    
               const urlredirect= localStorage.getItem('loginredirect');
      
               history("/", {replace:true})
            },[]);
            
        }
        
    });

  
}

export default LoginRedirect;