import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
//import { toast } from 'react-toastify';


//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, producturl}  from '../../environment';
import {dateFormat} from '../util/DateFormatter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTicket, faEnvelope, faHeartCircleMinus} from '@fortawesome/free-solid-svg-icons';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';



function ListFavorites(){

    const history = useNavigate();

    const[userData, setUserData]=useState(null);
    const[favorites, setFavorites]=useState([]);
    const[showEmailEvent, setShowEmailEvent]=useState(false);
    const[emailFavorite, setEmailFavorite]=useState(null);
    const[emailAddress, setEmailAddress]=useState(null);
    
    const handleSentEmailEvent=(e)=>{
        e.preventDefault();


    }

    const handleDisplayEmailEvent=(e)=>{
        setShowEmailEvent(true);
        //console.info(e);
        setEmailFavorite(e);
    }
    const handleTerminateDisplayEmailEventl=()=>{setShowEmailEvent(false)}

    const routeClick=(path)=>{
        history(path, {replace:true})
   }

    const handleSetFavorities=(userdata)=>{
        console.info("handleSetFavorities:")
        var favorites=[];


        //GET Favorites
        new RestServer().setUrl(conurl+'/api/cms/myfavorites/'+userdata.userid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(res=>{
            console.info("myfavorites: ")
            console.info(res.data);
            if(res.data.length===0){
                setFavorites([]);
            }else{
                res.data.map((product)=>{
                    new RestServer().setUrl(producturl+'/api/prod/event/'+product.productid)
                    .setMethod('GET')
                    .flagReturnData(true)
                    .connect()
                    .then(prodResponse=>{
                        favorites.push({
                            "favorite":product,
                            "product":prodResponse.data
                        });
                        console.info("event: ");
                        console.info(favorites);
                        setFavorites(favorites);
                        
                    });
                   
                });

            }
           
        
            },[]);    
    }

    const handleAddForm = (e)=>{
        e.preventDefault();

        console.info("handleAddForm->emailFavorite:")
        console.info(emailFavorite)

        const data ={
        
                    emailAddress:emailAddress,
                  
        }

        console.info(data);
       

        new RestServer().setUrl(conurl+'/api/cms/sendfavorites')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            console.info(response.data)
           // toast(response.data.message);
            handleTerminateDisplayEmailEventl();

            
        });
        
    }

    

    const handleRemoveFavorite=(e)=>{

        const data={
            favoriteid:e.favorite.favoriteid,
        }
        new RestServer().setUrl(conurl+'/api/cms/removefavorite')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            console.info("rtndata: ");
            console.info(rtndata);
            if(rtndata.messageTypeID===1){
                history("/favorites", {replace:true})
                handleSetFavorities(userData);
            }

        },[]);

    }


    

    useEffect(() => {
        const userdata= JSON.parse(localStorage.getItem('userdata'));
        //console.info(userdata);
        setUserData(userdata);
        handleSetFavorities(userdata);
    },[]);

    return(
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col lg={12}>
                            <Row className="p-b-25"><Col className="font-size-150 font-heavy8">My Favorites</Col></Row>
                            <Row>
                                {
                                    favorites?.map((favorite)=>{
                                        var image=favorite?.product.eventimage[0].imageloc+favorite.product?.eventimage[0].imagename;
                                        var produrl="/productdetail/"+favorite?.product.eventId;

                                        return<Col lg={2} xs={10} md={5} className="glass-container-1 m-r-50 m-b-50">
                                             <Row className="p-t-10">
                                                <Col>
                                                    <img src={image}  className="img-container-1 rad-tl-5 rad-tr-5"/>
                                                </Col>
                                            </Row>
                                            <Row className="p-t-10 font-size-100 font-heavy8 p-l-10 p-r-10">
                                                <Col>{favorite?.product.eventname}</Col>
                                            </Row>
                                            <Row className="p-t-5 font-size-75 font-heavy8 p-l-10 p-r-10 clr-1">
                                                <Col>{dateFormat.getMMMDDYYY(favorite?.product.eventdates[0])}</Col>
                                            </Row>


                                            <Row className="p-t-25 p-b-25 ">
                                                <Col>
                                                    <button className="icon-container-1" onClick={()=>{handleRemoveFavorite(favorite)}}>
                                                        <FontAwesomeIcon icon={faHeartCircleMinus} />
                                                        <span className="icon-container-1-title">Remove
                                                            <div className="icon-container-1-title-pointer"></div>
                                                        </span>
                                                    </button>
                                                </Col>
                                                <Col >
                                                    <button className="icon-container-1" onClick={()=>{handleDisplayEmailEvent(favorite)}}>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                        <span className="icon-container-1-title">Email
                                                            <div className="icon-container-1-title-pointer"></div>
                                                        </span>
                                                    </button>
                                                </Col>
                                                <Col>
                                                    <button className="icon-container-1" onClick={()=>{routeClick(produrl)}}>
                                                        <FontAwesomeIcon icon={faTicket} />
                                                        <span className="icon-container-1-title">Get Tickets
                                                            <div className="icon-container-1-title-pointer"></div>
                                                        </span>
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Col>

                                    })
                                }
                                
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/*Switch User Modal */}
            <Modal show={showEmailEvent} onHide={handleTerminateDisplayEmailEventl}>
                <Form onSubmit={handleAddForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Email Favorite</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="emailaddress" onChange={(s)=>{setEmailAddress(s.target.value)}}>
                                    <Form.Control className="clr-black" type="text" placeholder="Email Address" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleTerminateDisplayEmailEventl}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Email Favorite
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </React.Fragment>
    );
}
export default ListFavorites;