import React from 'react';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*COMPONENTS */
import TopHeader from '../components/ui/TopHeader';
import Popular from '../components/Home/popular';
import Footer from '../components/ui/footer';

function Home(){
    return(
        <React.Fragment>
            <Row className="bck-clr-primary-0">
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-25 ">
                <Col><Popular /></Col>
            </Row>
            
        </React.Fragment>
    );
}
export default Home;