import React from 'react';


/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*COMPONENTS */
import TopHeader from '../components/ui/TopHeader';
import PurchaseTickets from '../components/tickets/purchasetickets';
import Footer from '../components/ui/footer';

function Tickets(){
    return(
        <React.Fragment>
            <Row>
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-25">
               
                <Col >
                    <PurchaseTickets />
                </Col>
                
            </Row>
          
        </React.Fragment>
    );
}
export default Tickets;