import React from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//INTERNAL
import TopHeader from '../components/ui/TopHeader';
import TopNav from '../components/ui/topnav';
import TicketTitle from '../components/cart/tickettitle';
import TicketFilter from '../components/cart/ticketfilter';
import TicketLocations from '../components/cart/ticketlocations';
import TicketPurchase from '../components/cart/ticketpurchase';
import Footer from '../components/ui/footer';




function Cart(){
    return(
        <React.Fragment>
            <Row  className="bck-clr-primary-0">
                <Col><TopHeader /></Col>
            </Row>
            <Row className="p-t-25">
              
                <Col >
                    <TicketPurchase />
                </Col>
                
            </Row>
           
    
        
        </React.Fragment>
    );
}
export default Cart;